/* .bg-image {
  background-image: url(/src/assets/Ornament.svg);
  background-size: cover;
  background-position: center;
} */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');




/* Header.css */
.header-bg {
    background-color: #E5F8F9; /* Navigation background color */
  }
  
  .bg-image{
    background-image: url('/src/assets/Ornament.svg'); /* Path to your background image */
    background-size: cover;
    background-position: center;
    
  }
  

  .hr-style {
   
    height: 1px;
    border: 1.3px dashed #FFFFFF;
    background-color: #FFFFFF;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    opacity: 1;
    /* Add any other styles you need */
}



.hr-styledetails {
   
  height: 1px;
  border: 1.3px dashed #51CBD3;
  background-color: #51CBD3;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  opacity: 1;
  /* Add any other styles you need */
}



button.navbar-toggler.collapsed {
  background: #51CBD3;
}